import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LinkExternal from "../../components/link_external"
import OpenBook from "../../images/openbook.jpg"
import { useImpression } from "../../analytics/utils"

const commandments = [
  "I am the Lord thy God, thou shalt not have any strange gods before Me.",
  "Thou shalt not take the name of the Lord thy God in vain.",
  "Remember to keep holy the Sabbath day.",
  "Honor thy father and mother.",
  "Thou shalt not kill.",
  "Thou shalt not commit adultery.",
  "Thou shalt not steal.",
  "Thou shalt not bear false witness against thy neighbor.",
  "Thou shalt not covet thy neighbor’s wife.",
  "Thou shalt not covet thy neighbor’s goods.",
]

const TenCommandments = ({ location }) => {
  useImpression("TenCommandments")
  return (
    <Layout location={location}>
      <Seo
        title="The Ten Commandments"
        description="The Bible's Ten Commandments - Definition and meaning in Catholic religions."
      />
      <h1>The Ten Commandments</h1>
      <img
        src={OpenBook}
        alt="Open book of the Bible"
        width="300"
        style={{ float: "right", padding: 20 }}
      />
      <p>
        Christianity tells us that the commandments are guiding principles from
        God. Moses brought these commandments back from Mount Sinai on behalf of
        God. It's interesting to notice that the first three commandments are,
        how do you say, not really relevant compared to the rest. They even
        appear to depict a selfish god. One who is at some points claimed
        "omniscient", "omnipotent", but yet appears nervous that his
        subordinates might disobey his word. See for yourself.
      </p>
      <h2>The Commandments in order</h2>
      {commandments.map((commandment, i) => (
        <h3 key={commandment} style={{ fontSize: "98%", fontWeight: "normal" }}>
          {i + 1} &mdash; “{commandment}“
        </h3>
      ))}
      <p>
        You'll find the Ten Commandments in the Bible's Exodus and Deuteronomy.
        They are supposedly provided by God as guidance to all people. They
        center around ethics and worship.
      </p>
      <p>
        “And the Lord said unto Moses, Come up to me into the mount, and be
        there and I will give thee tablets of stone, and a law, and commandments
        which I have written; that thou mayest teach them. ...and Moses went up
        into the mount of God.“
        <span style={{ display: "block", textAlign: "right" }}>
          &mdash; Exodus 24:12–13
        </span>
      </p>
      <p>
        “If you think that it would be impossible to improve upon the Ten
        Commandments as a statement of morality, you really owe it to yourself
        to read some other scriptures. Once again, we need look no further than
        the Jains: Mahavira, the Jain patriarch, surpassed the morality of the
        Bible with a single sentence: 'Do not injure, abuse, oppress, enslave,
        insult, torment, torture, or kill any creature or living being.' Imagine
        how different our world might be if the Bible contained this as its
        central precept. Christians have abused, oppressed, enslaved, insulted,
        tormented, tortured, and killed people in the name of God for centuries,
        on the basis of a theologically defensible reading of the Bible.“
        <span style={{ textAlign: "right" }}>
          &mdash; Sam Harris,{" "}
          <LinkExternal to="https://samharris.org/books/letter-to-a-christian-nation/">
            Letter to a Christian Nation
          </LinkExternal>
        </span>
      </p>
    </Layout>
  )
}

export default TenCommandments
