import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const LinkExternal = ({ children, to }) => (
  <OutboundLink href={to} rel="noopener noreferrer" target="blank">
    {children}
  </OutboundLink>
)

export default LinkExternal
